import React, { useState, useEffect, Fragment } from 'react';
import { LocationClient, CalculateRouteCommand,CalculateRouteMatrixCommand, SearchPlaceIndexForTextCommand, SearchPlaceIndexForSuggestionsCommand, GetPlaceCommand, SearchPlaceIndexForPositionCommand, GetGeofenceCommand } from '@aws-sdk/client-location';
import { LogoControl } from 'maplibre-gl';

import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';
import { withAPIKey } from '@aws/amazon-location-utilities-auth-helper';
import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css';
import axios from 'axios';

import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import Select from "react-select";
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl-csp-worker';
import logo from "./logo192.png"


maplibregl.workerClass = require('maplibre-gl/dist/maplibre-gl-csp-worker');


const App = () => {
  const [map, setMap] = useState(null);
  const [startEnd, setStarEnd] = useState([])
  const [geocoder, setGeocoder] = useState(null);
  const [route, setRoute] = useState(null)
  const [selectDriverType, setSelectDriverType] = useState(null);
  const [responseData, setResponseData] = useState([])
  const [isSubmitting, setSubmitting] = useState(false)
  const apiKey = 'v1.public.eyJqdGkiOiJlZmExMmJkYi01ZDRjLTRhNDYtYWU4MC1jNGYwOWI3YTdhMzcifWIxNqOZ_j457XEoB0MRcamDyi2b2EEHe-p9fs_IzsvaPcpO6Z_bSsw0kV6Pd3ZbVIZooPM57PPwPjDvpKim6NmhgihXugNyixKEAmaKg-3YnuHLnDFS-GVk0sxkFwY99TuhTZXVivuXzKtswQu8Oq-OCQ8AGN3i-YKgEm08j2SSDmg9M1qmlotQ9AE_JZxIjzoO2fDva6QrZp8RJhEAfE5aj_XoOSrJcxnSzJsBxAsXlxz8d0AnpUuvuayO6O5u5XZ9VKwcka4LwFmPWrrbK45_Fg0MWmxU9SbfT4VcX81ubf5ghRIf7jOlXMj9K5KCSp1H11qBlNkwOFlqxhX49r8.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx'; // Replace with your Amazon Location API key
  const mapName = 'FlashCat'; // Replace with your map resource name
  const placeIndex = 'FlashCatIndex-dev'; // Replace with your place index resource name
  const [region, setRegion] = useState('us-east-1');
  const [coordinatesArray, setCoordinatesArray] = useState([])
  const [duration, setDuration] = useState()
  const [coordinatesDelivery, setCoordinatesDelivery] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [startLabel, setStartLabel] = useState([])
  const [EndLabel, setEndLabel] = useState([])
  window.g = 10;
  const Routecalculation = async () => {
    const authHelper = await withAPIKey(apiKey);
    const clients = new LocationClient({
      region,
      ...authHelper.getLocationClientConfig(),
    });
    if (startEnd.length == 2) {
      const firstPlaceData = startEnd[0];
      const secondPlaceData = startEnd[1];

      const input = {
        CalculatorName: "FlashcatCalculator",
        DeparturePosition: [firstPlaceData?.latitude, firstPlaceData?.longitude],
        DestinationPosition: [secondPlaceData?.latitude, secondPlaceData?.longitude],
        TravelMode: 'Car',
        IncludeLegGeometry: true

      };
      const command = new CalculateRouteCommand(input);
      const response = await clients.send(command);

      setRoute(response)
    }
  }
  const RoutecalculationMatrix = async () => {
    const authHelper = await withAPIKey(apiKey);
    const clients = new LocationClient({
      region,
      ...authHelper.getLocationClientConfig(),
    });
    if (coordinatesDelivery.length>0) {
      const filteredData = coordinatesDelivery.map(item => item.cordinates);
      console.log('Nushara',coordinatesDelivery);
      console.log('Nushara',filteredData);
      const firstPlaceData = startEnd[0];
      const secondPlaceData = startEnd[1];
      
      const input = {
        CalculatorName: "FlashcatCalculator",
        DeparturePositions:filteredData,
        DestinationPositions: filteredData,
        TravelMode: 'Car',
        DistanceUnit:'Miles', 
        IncludeLegGeometry: true

      };
      const command = new CalculateRouteMatrixCommand(input);
      
      const response = await clients.send(command);
      console.log('Anavia',response);
      // setRoute(response)
    }
  }
  
  const data = [{ id: "DX5046329871", PostalCode: "92316", Street: "Bloomington, CA" },
  { id: "DX6481057932", PostalCode: "92376", Street: "Rialto, CA " },
  { id: "DX6501427389", PostalCode: "92504", Street: "Riverside, CA" },
  { id: "DX4091837652", PostalCode: "92336", Street: "Fontana CA" },
  { id: "DX 9207536184", PostalCode: "92882", Street: "Corona, CA" },


  ]
  const convertCoordinatesToLabels = async () => {

    const firstPlaceData = startEnd[0];
    const secondPlaceData = startEnd[1];



    const authHelper = await withAPIKey(apiKey);

    const client = new LocationClient({
      region,
      ...authHelper.getLocationClientConfig(),
    });

    const { latitude, longitude } = firstPlaceData;

    const command = new SearchPlaceIndexForPositionCommand({
      IndexName: placeIndex,
      Position: [latitude, longitude],
      FilterCountries: ["USA"]
    });

    try {
      const response = await client.send(command);
      const results = response.Results;



      if (results.length > 0) {

        setStartLabel(results[0].Place.Label);
        if (secondPlaceData) {


          const { latitude, longitude } = secondPlaceData

          const commandDest = new SearchPlaceIndexForPositionCommand({
            IndexName: placeIndex,
            Position: [latitude, longitude],
            FilterCountries: ["USA"]
          });

          try {

            const response = await client.send(commandDest);
            const results = response.Results;


            if (results.length > 0) {

              setEndLabel(results[0].Place.Label);


            }
          } catch (error) {
            console.error(`Failed to convert coordinates to address: ${error}`);
          }
        }

      }
    } catch (error) {
      console.error(`Failed to convert coordinates to address: ${error}`);
    }

  };

  useEffect(() => {
    if (startEnd.length > 0) {
      convertCoordinatesToLabels();
    }
  }, [startEnd]);

  let flag = 0;
  const handleSubmit = (e) => {
    // Handle form submission here
    e.preventDefault();

    setSubmitting(false);

    var formData = new FormData();
    formData.append('facility_id', 6);
    formData.append('user_id', 2);
    formData.append('user_type', 1);
    formData.append('startingPosition', startLabel);
    formData.append('endPosition', EndLabel);
    formData.append('driver_id', selectDriverType.id);
    formData.append('orders', JSON.stringify(coordinatesDelivery));
    formData.append('distance', route ? route.Summary.Distance : "");
    formData.append('duration', duration ? duration : "");
    formData.append('route',route?JSON.stringify(route.Legs):"")
    // https://flashcat-api.gettridant.com/webservice/transportation/add-route
    // http://localhost/flashcat/webservice/transportation/add-route
    axios.post('https://flashcat-api.gettridant.com/webservice/transportation/add-route', formData).then((res) => {
      var data = res.data;
      // window.location.href = 'http://localhost:5000/app/transportation/routes';

      window.location.href = 'https://flashcat.gettridant.com/app/transportation/routes';

    }).catch(e => {

      console.log("Addition failed , Error ", e);
    });
  };
  const calculateCoordinates = async (data) => {
    const authHelper = await withAPIKey(apiKey);
    const client = new LocationClient({
      region,
      ...authHelper.getLocationClientConfig(),
    });

    const coordinatesArray = [];
    if (flag == 0) {

      for (const item of data) {

        const params = {
          IndexName: placeIndex,
          Text: item.PostalCode,
          FilterCountries: ["USA"]
          // FilterCountries: "INDIA"
        };

        try {

          const command = new SearchPlaceIndexForTextCommand(params)
          const response = await client.send(command);
          const result = response.Results;


          if (result) {

            coordinatesArray.push({ id: item.id, name: item.name,phone:item.phone,address:item.vendor_address+"<br/>"+item.vendor_city+","+item.state, cordinates: result[0].Place.Geometry.Point });
          } else {
            console.error(`Location not found for ${item.PostalCode} ${item.Street}`);
          }        } catch (error) {
          console.error(`Error calculating coordinates: ${error.message}`);
        }
      }
      flag = 1;
    }


    setCoordinatesArray(coordinatesArray)
    return coordinatesArray
  }

  function filterCoordinates(coordinates, boundingBox) {
    const filteredCoords = coordinates.filter((coord) => {

      const [minLon, minLat, maxLon, maxLat] = boundingBox;
      console.log('####', coord.cordinates);
      coord.lngLat = {
        lng: coord.cordinates[0],
        lat: coord.cordinates[1],
      };
      const [lon, lat] = coord.cordinates;
      if (lon >= minLon && lon <= maxLon && lat >= minLat && lat <= maxLat) {
        coordinatesDelivery.push(coord);

      }

    });
    setCoordinatesDelivery(coordinatesDelivery);

  }
  const fetchDeliveryData = async () => {


    var formData = new FormData();
    formData.append('facility_id', 6);
    formData.append('user_id', 2);
    formData.append('user_type', 1);
    // https://flashcat-api.gettridant.com/webservice/transportation/get-all-delivery-data
    // http://localhost/flashcat/webservice/transportation/get-all-delivery-data
    axios.post('https://flashcat-api.gettridant.com/webservice/transportation/get-all-delivery-data', formData).then((res) => {
      var data = res.data;

      if (data.delivery) {
        var responseData = data.delivery;

        let drivers = data.drivers.map(row => {
          row.label = row.name;
          row.value = row.id;
          row.key = row.id;
          return row;
        });


        setDrivers(drivers)
        setResponseData(responseData);

      }
    }).catch(e => {

      console.log("Addition failed , Error ", e);
    });
  }
  useEffect(() => {
    const initMap = async () => {

      const authHelper = await withAPIKey(apiKey);

      const client = new LocationClient({
        region,
        ...authHelper.getLocationClientConfig(),
      });


      const map = new maplibregl.Map({

        container: 'map',
        style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
        center: [-123.1187, 49.2819],
        zoom: 2,
      });

      const amazonLocationGeocoderApi = {
        forwardGeocode: async (config) => {
          try {
            const { Results } = await client.send(new SearchPlaceIndexForTextCommand({
              IndexName: placeIndex,
              Text: config.query,
            }));

            const features = Results.map((result) => (
              {

                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: result.Place.Geometry.Point,
                },
                place_name: result.Place.Label,
                properties: {
                  id: result.Place.PlaceId,
                },
                text: result.Place.Label,
                place_type: ['place'],
                center: result.Place.Geometry.Point,
              }));



            return { features };
          } catch (error) {
            console.error(`Failed to forwardGeocode with error: ${error}`);
          }
        },
        getSuggestions: async (config) => {
          try {
            const { Results } = await client.send(new SearchPlaceIndexForSuggestionsCommand({
              IndexName: placeIndex,
              Text: config.query,
            }));

            const suggestions = Results.map((result) => ({
              text: result.Text,
              placeId: result.PlaceId,
            }));

            return { suggestions };
          } catch (error) {
            console.error(`Failed to getSuggestions with error: ${error}`);
          }
        },
        searchByPlaceId: async (config) => {
          try {
            const { Place } = await client.send(new GetPlaceCommand({
              IndexName: placeIndex,
              PlaceId: config.query,
            }));
            if (Place) {
              const newPlaceData = {
                latitude: Place?.Geometry?.Point[0],
                longitude: Place?.Geometry?.Point[1],
              };

              setStarEnd((prevStartEnd) => [...prevStartEnd, newPlaceData]);

              const place = {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: Place.Geometry.Point,
                },
                place_name: Place.Label,
                text: Place.Label,
                center: Place.Geometry.Point,

              };

              return { place };
            }
          } catch (error) {
            console.error(`Failed to searchByPlaceId with error: ${error}`);
          }
        },
      };
      const geocoder = new MaplibreGeocoder(amazonLocationGeocoderApi, {
        maplibregl: maplibregl,
        showResultsWhileTyping: true,
        placeholder: 'Enter starting point',
      });

      const toGeocoder = new MaplibreGeocoder(amazonLocationGeocoderApi, {
        maplibregl: maplibregl,
        showResultsWhileTyping: true,
        placeholder: 'Enter Destination point',
      });

      map.addControl(geocoder);
      map.addControl(toGeocoder);
      setMap(map);

    };
    initMap();


  }, []);
  useEffect(() => {
    Routecalculation()
    
    if (startEnd?.length == 2) {
      convertCoordinatesToLabels()

    }
  }, [startEnd])

  useEffect(() => {
    if (map && route) {

      let dataList = []
      const coordinates = route.Legs[0].Geometry.LineString
      let RouteBBox = route.Summary.RouteBBox;

      let hours = Math.floor(route.Summary.DurationSeconds / 3600);
      let minutes = Math.floor((route.Summary.DurationSeconds % 3600) / 60);
      let seconds = route.Summary.DurationSeconds % 60;
      let duration = hours + "h  " + minutes + "m  " + seconds + "s  "
      setDuration(duration)

      if (coordinatesArray.length > 0) {

        filterCoordinates(coordinatesArray, RouteBBox)

      }
      if (coordinates && coordinates.length > 0) {
        /// for sample 
        map.addLayer({
          id: 'route',
          type: 'line',
          source: {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: coordinates
              }

            },

          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#3b9ddd',
            'line-width': 5,
          },
        });
        const bounds = coordinates.reduce((bounds, coord) => {
          return bounds.extend(coord);
        }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));

        // Fit the map to the bounds with some padding (optional)
        map.fitBounds(bounds, {
          padding: 50, // Adjust this value to control the padding around the route
        });
        if (coordinatesDelivery) {

          RoutecalculationMatrix()
          let x = 5
          // coordinatesDelivery.forEach(function (coord) {

          //   var marker =new maplibregl.Marker()
          //   .setLngLat(coord.lngLat)
          //   .setPopup(new maplibregl.Popup().setHTML(coord.name))
          //   .setOffset({ x:x, y: 0 })
          //   .addTo(map);
          //   marker.getElement().setAttribute('title', coord.name);
          //   x=x+15;

          // });

          coordinatesDelivery.forEach(function (coord) {
            // Create the main marker
            var marker = new maplibregl.Marker()
                .setLngLat(coord.lngLat)
                .setPopup(new maplibregl.Popup().setHTML(
                  `<div style="font-size: 16px; color: blue;">
                 <strong>Name:</strong>${coord.name}<br/>
                 <strong>Contact No:</strong>${coord.phone}<br/>
                 <strong>Address:</strong>${coord.address}
              </div>`))
              .setOffset({ x:x, y: 0 })
                .addTo(map);
        
            // Create a text element for coord.id
            var idTextElement = document.createElement('div');
            idTextElement.style.position = 'absolute';
            idTextElement.style.top = '34%';
            idTextElement.style.left = '45%';
            idTextElement.style.transform = 'translate(-50%, -50%)';
            idTextElement.style.fontSize = '12px'; // Adjust the font size as needed
            idTextElement.style.color = 'black'; // Adjust the text color as needed
            idTextElement.innerHTML = coord.id;
        
            // Append the text element to the marker container
            marker.getElement().appendChild(idTextElement);
            x=x+15;
        });
        
        


        }
      }
    }
  }, [map, route]);
  useEffect(() => {


    if (responseData.length > 0) {
      calculateCoordinates(responseData)
    }


  }, [coordinatesArray, responseData]);
  useEffect(() => {



  }, [coordinatesDelivery]);
  useEffect(() => {


    if (responseData.length === 0) {

      fetchDeliveryData();
    }
    // if(responseData.length>0){
    //   calculateCoordinates(responseData)
    // }


  }, [responseData, drivers]);

  useEffect(() => {



  }, [startLabel, EndLabel]);
  return (

    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: '500' }}>
        <img src={logo} alt="Company Logo" width="100px" height="auto" />
      </div>
      <div id="map" style={{ width: '100%', position: 'relative' }}>

      </div>
      {route ? (
        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            paddingRight: '14px',
            paddingLeft: '14px',
            paddingTop: '110px',
            borderRadius: '5px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          {/* <h4 style={{ fontSize: '25px' }}>Route Summary</h4> */}
          <div style={{ fontSize: '17px', fontWeight: 700 }}>
            Distance {route.Summary.Distance}
            {route.Summary.DistanceUnit}
          </div>
          <div style={{ fontSize: '17px', fontWeight: 700 }}>
            Duration {duration ? duration : ''}
          </div>
          <Form onSubmit={handleSubmit}>
            <div style={{ paddingBottom: '20px' }}>
              <Select
                className="react-select"
                classNamePrefix="react-select"
                name="driver_id"
                id="driver_id"
                placeholder="Assign Driver"
                value={selectDriverType}
                options={drivers}
                onChange={(selectedOption) => {
                  setSelectDriverType(selectedOption);
                }}
              />
              <Button
                style={{
                  borderRadius: '5px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingTop: '7px',
                  paddingBottom: '7px',
                  marginTop: '10px',
                  background: '#f16209',
                  border: 'red',
                  color: '#fff',
                }}
                type="submit"
                color="primary"
                disaple={setSelectDriverType ? false : true}
                onSubmit={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        ''
      )}
    </div>

  );
};

export default App;
